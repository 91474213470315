<template lang="pug">
.container-user.finance-forms-collection(
  v-show="isFormsVisible"
)
  .finance-forms-title {{ $t("financeFormSelect.text") }}:
  .finance-forms-list
    label.finance-forms-label.custom-check(
      v-for="(item, key) in financeFormsFiltered"
      :for="`finance-form-${key}`"
      :key="key"
    )
      input(
        type="radio"
        name="finance-form"
        class="custom-check-input finance-forms-input"
        :id="`finance-form-${key}`"
        :checked="item.id === financeFormId"
        :value="item.name"
        @change="changeFinanceForm(item)"
      )
      .check
        Check
      .custom-check-text
        span.finance-forms-text {{ $t(`financeForms.${camelize(item.name)}`) }}
</template>

<script>
import {mapState, mapActions, mapGetters, } from 'vuex'

import { isMobileWidth, camelize, } from '@/helpers/helpers'

export default {
  name: 'FinanceForms',
  data: () => ({
    isFormsVisible: true,
  }),
  components: {
    Check: () => import('@/components/svg/check'),
  },
  computed: {
    ...mapState('global', ['generalFormsCollectionName', ]),
    ...mapState('filter', {
      financeForms: state => state.allFinanceForms,
      financeFormId: state => state.finance_form_id,   
    }),
    ...mapGetters('filter', ['getFilteredFinanceForms']),
    financeFormsFiltered() {
      const filteredFinanceForms = this.getFilteredFinanceForms(this.generalFormsCollectionName);

      return !filteredFinanceForms.length ? [] : filteredFinanceForms;
    },
  },
  created() {
    const _this = this;

    _this.setFormsVisibily()

    window.addEventListener('resize', function() {
      _this.setFormsVisibily();
    })
  },
  methods: {
    camelize,
    ...mapActions('filter', [ 'updateFinanceForm', ]),
    ...mapActions('reseller', [ 'updateBuilder', ]),
    async changeFinanceForm(form) {
      await this.updateFinanceForm(form);
      this.$emit('change');

      await this.updateBuilder();

      await this.$router.replace({ query: { ...this.$route.query, finance_form: form.name, } });
    },
    setFormsVisibily() {
      const visibility = !isMobileWidth() && this.financeFormsFiltered.length > 1;

      this.$set(this.$data, 'isFormsVisible', visibility);
    },

    financeFormInit() {

      const financeFormFromQuery = this.financeFormsFiltered.find(item => item.name === this.$route.query.finance_form);
      if (financeFormFromQuery) return this.updateFinanceForm(financeFormFromQuery);

      const defaultFinanceForm = this.financeFormsFiltered.find(item => item.default);
      if (defaultFinanceForm && this.financeFormId === null) return this.updateFinanceForm(defaultFinanceForm);

    }
  },
  watch: {
    financeForms: {
      handler: function(n) {
        if (!n.length) return

        this.$set(this.$data, 'isFormsVisible', !isMobileWidth() && this.financeFormsFiltered.length > 1);
        this.financeFormInit();
      },
      deep: true,
    },
  }
}
</script>